<template>
  <b-card class="border-primary rounded-lg shadow-lg">
    <!-- form -->
    <validation-observer ref="accountInformationForm" #default="{ invalid }">
      <b-form @submit.prevent="updateProfile">
        <b-row>
          <!-- bio -->
          <b-col cols="12">
            <b-form-group
              label="About"
              label-for="bio-area"
            >
              <b-form-textarea
                id="bio-area"
                v-model="booking.profile"
                rows="4"
                placeholder="Your introduction here..."
              />
            </b-form-group>
          </b-col>
          <!--/ bio -->

          <!-- birth date -->
          <b-col md="12">
            <b-form-group label="Industry" label-for="register-industry">
                <validation-provider
                  #default="{ errors }"
                  name="Industry"
                  vid="industry"
                >
                  <b-form-select
                    id="register-industry"
                    v-model="booking.industry"
                    label="industry"
                    :options="[
                      { value: null, text: '' },
                      {value: 'Accounting', text: 'Accounting'},
                      {value: 'Advertising &amp;Public Relations', text: 'Advertising &amp;Public Relations'},
                      {value: 'App Development', text: 'App Development'},
                      {value: 'Banking', text: 'Banking'},
                      {value: 'Business', text: 'Business'},
                      {value: 'Civil Engineering', text: 'Civil Engineering'},
                      {value: 'Consulting', text: 'Consulting'},
                      {value: 'Customer Service', text: 'Customer Service'},
                      {value: 'Data Science', text: 'Data Science'},
                      {value: 'Education', text: 'Education'},
                      {value: 'Electronics', text: 'Electronics'},
                      {value: 'Entrepreneurship', text: 'Entrepreneurship'},
                      {value: 'Environmental Science', text: 'Environmental Science'},
                      {value: 'Events', text: 'Events'},
                      {value: 'Fashion', text: 'Fashion'},
                      {value: 'Film and Television', text: 'Film and Television'},
                      {value: 'Finance', text: 'Finance'},
                      {value: 'Freelancing', text: 'Freelancing'},
                      {value: 'Government', text: 'Government'},
                      {value: 'Healthcare', text: 'Healthcare'},
                      {value: 'HR', text: 'HR'},
                      {value: 'Industrial Engineering', text: 'Industrial Engineering'},
                      {value: 'Insurance and Risk Management', text: 'Insurance and Risk Management'},
                      {value: 'IT', text: 'IT'},
                      {value: 'Legal Services', text: 'Legal Services'},
                      {value: 'Manufacturing', text: 'Manufacturing'},
                      {value: 'Marketing', text: 'Marketing'},
                      {value: 'Mechanical Engineering', text: 'Mechanical Engineering'},
                      {value: 'Media', text: 'Media'},
                      {value: 'Medical Science', text: 'Medical Science'},
                      {value: 'Public Sector', text: 'Public Sector'},
                      {value: 'Publishing', text: 'Publishing'},
                      {value: 'Retail', text: 'Retail'},
                      {value: 'Security', text: 'Security'},
                      {value: 'Software Engineering', text: 'Software Engineering'},
                      {value: 'Software Manufacturing', text: 'Software Manufacturing'},
                      {value: 'Technical', text: 'Technical'},
                      {value: 'Trading', text: 'Trading'},
                      {value: 'Travel sector', text: 'Travel sector'},
                    ]"
                    name="register-industry"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
          </b-col>
          <!--/ birth date -->

          <b-col cols="12">
            <b-button
              type="submit"
              :disabled="invalid"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              class="mt-1"
              variant="outline-secondary"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, } from "@validations";
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    ValidationProvider, 
    ValidationObserver,
    vSelect,
    flatPickr,
    Cleave,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      booking: this.userData,
      required,
    }
  },
  methods: {
    resetForm() {
      this.booking = this.userData
    },
    updateProfile() {
      // console.log('updatedProfile', this.booking)
      this.$store.dispatch('account/updateProfile', this.booking)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
