<template>
  <b-card class="border-primary rounded-lg shadow-lg">
     <validation-observer ref="accountSocialForm" #default="{ invalid }">
      <b-form @submit.prevent="updateProfile">
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <feather-icon
                icon="LinkIcon"
                size="18"
              />
              <h4 class="mb-0 ml-75">
                Social Links
              </h4>
            </div>
          </b-col>

          <!-- twitter -->
          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="Twitter"
                vid="twitter"
                rules="url"
            >
              <b-form-group
                label-for="account-twitter"
                label="Twitter"
              >
                <b-form-input
                  id="account-twitter"
                  type="url"
                  v-model="booking.social.twitter"
                  placeholder="Add link"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!--/ twitter -->

          <!-- facebook -->
          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="Facebook"
                vid="facebook"
                rules="url"
            >
              <b-form-group
                label-for="account-facebook"
                label="Facebook"
              >
                <b-form-input
                  type="url"
                  id="account-facebook"
                  v-model="booking.social.facebook"
                  placeholder="Add link"
                />
              </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!--/ facebook -->

          <!-- linkedin -->
          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="LinkedIn"
                vid="linkedin"
                rules="url"
            >
              <b-form-group
                label-for="account-linkedin"
                label="LinkedIn"
              >
                <b-form-input
                  type="url"
                  id="account-linkedin"
                  v-model="booking.social.linkedin"
                  placeholder="Add link"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!-- linkedin -->

          <!-- instagram -->
          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="Instagram"
                vid="instagram"
                rules="url"
            >
            <b-form-group
              label="Instagram"
              label-for="account-instagram"
            >
              <b-form-input
                type="url"
                id="account-instagram"
                v-model="booking.social.instagram"
                placeholder="Add link"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!--/ instagram -->

          <!-- youtube -->
          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="YouTube"
                vid="youtube"
                rules="url"
            >
            <b-form-group
              label-for="account-youtube"
              label="YouTube"
            >
              <b-form-input
                type="url"
                id="account-youtube"
                v-model="booking.social.youtube"
                placeholder="Add link"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!--/ youtube -->

          <!-- website -->
          <b-col md="6">
            <validation-provider
                #default="{ errors }"
                name="Website"
                vid="website"
                rules="url"
            >
            <b-form-group
              label-for="account-website"
              label="Website"
            >
              <b-form-input
                type="url"
                id="account-website"
                v-model="booking.social.website"
                placeholder="Add link"
              />
            </b-form-group>
             <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!--/ website -->

          <b-col cols="12">
            <hr class="my-2">
          </b-col>

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              type="submit"
              :disabled="invalid"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              class="mt-1 ml-25"
              variant="outline-secondary"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
     </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { url, } from "@validations";
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BLink, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    ValidationProvider, 
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      booking: this.userData,

      // validations
      url,
    }
  },
  methods: {
    resetForm() {
      this.booking = this.userData
    },
    updateProfile() {
      // console.log('updatedProfile', this.booking)
      this.$store.dispatch('account/updateProfile', this.booking)
    },
  },
}
</script>
