var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"border-primary rounded-lg shadow-lg"},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',[(_vm.booking.avatar)?_c('b-avatar',{attrs:{"size":"100","text":_vm.avatarText(_vm.booking.name || _vm.booking.firstname + ' ' + (_vm.booking.middlename ? _vm.booking.middlename + ' ' : '') + _vm.booking.lastname ),"src":_vm.booking.avatar,"variant":"light-primary"}},[(!_vm.booking.name)?_c('feather-icon',{attrs:{"icon":"UserIcon","size":"32"}}):_vm._e()],1):_vm._e(),(!_vm.booking.avatar)?_c('avatar',{attrs:{"variant":"bauhaus","size":100,"colors":['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90'],"name":_vm.booking.name || _vm.booking.firstname + ' ' + (_vm.booking.middlename ? _vm.booking.middlename + ' ' : '') + _vm.booking.lastname}}):_vm._e()],1),_c('b-media-body',{staticClass:"mt-75 ml-75"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75 mr-75",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$refs.refInputEl.$el.click()}}},[_vm._v(" Upload ")]),_c('b-form-file',{ref:"refInputEl",attrs:{"accept":".jpg, .png, .gif","hidden":true,"plain":""},on:{"input":_vm.userAvatarUpdated},model:{value:(_vm.profileFile),callback:function ($$v) {_vm.profileFile=$$v},expression:"profileFile"}}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75 mr-75",attrs:{"variant":"outline-secondary","size":"sm"}},[_vm._v(" Reset ")]),_c('b-card-text',{staticClass:"small"},[_vm._v("Allowed JPG, GIF or PNG.")])],1)],1),_c('validation-observer',{ref:"accountGeneralForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.updateProfile($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Title *","label-for":"register-title"}},[_c('validation-provider',{attrs:{"name":"Title","vid":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"register-title","label":"title","options":[
                  { value: null, text: '' },
                  { value: 'Mx', text: 'Mx' },
                  { value: 'Ms', text: 'Ms' },
                  { value: 'Mr', text: 'Mr' },
                  { value: 'Dr', text: 'Dr' },
                  { value: 'Prof', text: 'Prof' } ],"name":"register-title"},model:{value:(_vm.booking.title),callback:function ($$v) {_vm.$set(_vm.booking, "title", $$v)},expression:"booking.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":"First Name *","label-for":"register-firstname"}},[_c('validation-provider',{attrs:{"name":"First Name","vid":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-firstname","state":errors.length > 0 ? false : null,"name":"register-firstname","placeholder":""},model:{value:(_vm.booking.firstname),callback:function ($$v) {_vm.$set(_vm.booking, "firstname", $$v)},expression:"booking.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Middle Name","label-for":"register-middlename"}},[_c('validation-provider',{attrs:{"name":"Middle Name","vid":"middlename","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-middlename","state":errors.length > 0 ? false : null,"name":"register-middlename","placeholder":""},model:{value:(_vm.booking.middlename),callback:function ($$v) {_vm.$set(_vm.booking, "middlename", $$v)},expression:"booking.middlename"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Last Name *","label-for":"register-lastname"}},[_c('validation-provider',{attrs:{"name":"Last Name","vid":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-lastname","state":errors.length > 0 ? false : null,"name":"register-lastname","placeholder":""},model:{value:(_vm.booking.lastname),callback:function ($$v) {_vm.$set(_vm.booking, "lastname", $$v)},expression:"booking.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Email *","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","state":errors.length > 0 ? false : null,"name":"register-email","placeholder":"your.name@example.com"},model:{value:(_vm.booking.email),callback:function ($$v) {_vm.$set(_vm.booking, "email", $$v)},expression:"booking.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Mobile","label-for":"register-mobile"}},[_c('validation-provider',{attrs:{"name":"Mobile","vid":"tel","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-mobile","state":errors.length > 0 ? false : null,"name":"register-mobile","placeholder":""},model:{value:(_vm.booking.mobile),callback:function ($$v) {_vm.$set(_vm.booking, "mobile", $$v)},expression:"booking.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Organization","label-for":"register-organization"}},[_c('validation-provider',{attrs:{"name":"Organization","vid":"organization","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-organization","state":errors.length > 0 ? false : null,"name":"register-organization","placeholder":""},model:{value:(_vm.booking.organization),callback:function ($$v) {_vm.$set(_vm.booking, "organization", $$v)},expression:"booking.organization"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Job Title","label-for":"register-designation"}},[_c('validation-provider',{attrs:{"name":"Job Title","vid":"","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-designation","state":errors.length > 0 ? false : null,"name":"register-designation","placeholder":""},model:{value:(_vm.booking.designation),callback:function ($$v) {_vm.$set(_vm.booking, "designation", $$v)},expression:"booking.designation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"type":"submit","disabled":invalid,"variant":"primary"}},[_vm._v(" Save changes ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_vm._v(" Reset ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }