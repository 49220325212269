<template>
  <div>
    <!-- <b-carousel
      id="reception-1"
      :interval="10000"
      fade
      controls
      indicators
      background="#ffffff"
      img-width="100%"
      img-height="100%"
      class="rounded-lg"
      style="text-shadow: 1px 1px 2px #333;"
    >
      <b-carousel-slide v-for="(slide, i) in slides" :key="i" :img-src="slide.image"></b-carousel-slide>
    </b-carousel> -->
    <swiper
      class="swiper-autoplay swiper-coverflow"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide v-for="(slide, index) in slides" :key="index">
        <b-img :src="slide.image" fluid />
      </swiper-slide>
      <div slot="pagination" class="swiper-pagination" />
      <div slot="button-next" class="swiper-button-next" />
      <div slot="button-prev" class="swiper-button-prev" />
    </swiper>
    <b-card class="mt-1 border-primary shadow">
      <b-card-body class="d-flex flex-md-row flex-column justify-content-between align-item-start">
        <div>
          <b-img :src="event.logo" width="auto" height="100px" left :alt="event.title" class="mr-2 mx-auto mx-md-0" />
          <h2>{{ event.title }}</h2>
          <h4>{{ event.subtitle }}</h4>
          <strong >{{ $date(event.scheduledStart).local().format("DD MMM YYYY HH:mm") }} to {{ $date(event.scheduledEnd).local().format("DD MMM YYYY HH:mm") }}</strong >
        </div>
        <div>
          Hosted by
          <div v-if="event && event.organizer && Array.isArray(event.organizer)" class="d-flex flex-column flex-md-row">
            <b-row>
              <b-col v-for="organizer in event.organizer" class="rounded-lg box-shadow-2 m-1 p-1" :key="organizer.name">
                <b-link :id="organizer.name" :href="organizer.website" target="_blank" class="d-flex flex-column">
                  <b-img :src="organizer.logo" height="auto" width="100px" :alt="organizer.name" />
                </b-link>
                <b-popover :target="organizer.name" triggers="hover" custom-class="rounded-lg box-shadow-4 text-center" placement="auto">
                    <b-img :src="organizer.logo" style="max-width: 250px; height: auto; object-fit: contain;" /><br />
                    {{organizer.name}}
                  </b-popover>
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <b-link :href="event.organizer.website" target="_blank">{{ event.organizer.name }}</b-link>
          </div>
        </div>  
        <h4 v-if="$date(event.scheduledEnd).local().isBefore($date())" class="text-secondary mt-2 mb-0">The event has concluded!</h4>
      </b-card-body>
    </b-card>

    <!-- Featured Agenda -->
    <b-row align-h="between" v-if="sessions && sessions.length > 0">
      <b-col cols="12" class="d-flex flex-row align-items-baseline"><h2>Featured Sessions</h2> <b-link href="/sessions" class="ml-1">View All</b-link></b-col>
    </b-row>
    <b-row class="match-height" v-if="sessions && sessions.length > 0">
      <b-col cols="12" md="4" v-for="(session, index) in sessions" :key="index">
        <agenda-card :data="session" :description="true" :location="true" :customStyle="event.meta.style.agendaCard || ''" />
      </b-col>
    </b-row>
    <!-- Featured Agenda End-->

    <!-- Featured Partners -->
    <b-row align-h="between" v-if="partners && partners.length > 0">
      <b-col cols="12" class="d-flex flex-row align-items-baseline"><h2>Sponsors</h2> <b-link href="/partners" class="ml-1">View All</b-link></b-col>
    </b-row>
    <b-row class="match-height" v-if="partners && partners.length > 0">
      <b-col v-for="(partner, index) in partners" :key="index" cols="12" lg="3" md="4" >
        <partner-card :data="partner" />
      </b-col>
    </b-row>
    <!-- Featured Partners End -->
    <!-- <b-modal
      id="modal-profile"
      v-model="requestProfileUpdate"
      centered
      cancel-disabled
      title="Your Profile"
      size="xl"
      footer-class="d-none"
    >
      <account-setting />
    </b-modal> -->
  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BCardBody,
  BCardText,
  BCardFooter,
  BLink,
  BImg,
  BCarousel,
  BCarouselSlide,
  BRow,
  BCol,
  BMedia,
  BModal,
  BPopover,
} from "bootstrap-vue";
import { heightFade } from '@core/directives/animations'
import { $themeConfig } from "@themeConfig";
import AccountSetting from '@/views/pages/account-setting/AccountSetting.vue'
import AgendaCard from "@/views/pages/sessions/AgendaCard.vue";
import PartnerCard from '@/views/pages/partners/PartnerCard.vue'
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import 'swiper/css/swiper.css'
import store from "@/store/index";

export default {
  metaInfo: {
    title: "Reception",
  },
  components: {
    Swiper,
    SwiperSlide,
    BBadge,
    BCard,
    BCardText,
    BCardBody,
    BCardFooter,
    BLink,
    BImg,
    BCarousel,
    BCarouselSlide,
    BRow,
    BCol,
    BMedia,
    BModal,
    BPopover,
    AgendaCard,
    PartnerCard,
    AccountSetting,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      showProfileModal: false,
      swiperOptions: {
        loop: true,
        // effect: 'coverflow',
        autoHeight: true,
        spaceBetween: 30,
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "1.6",
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
        // pagination: {
        //   el: '.swiper-pagination',
        // },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  // watch: {
  //   requestProfileUpdate() {
  //     this.showProfileModal = this.requestProfileUpdate
  //   }
  // },
  computed: {
    // requestProfileUpdate() {
    //   return store.getters['account/requestProfileUpdate']
    // },
    slides() {
      return store.state.event.pages.reception.banners
    },
    event() {
      return store.getters["event/getEvent"];
    },
    partners() {
      return store.getters["partners/byPage"]('reception');
    },
    sessions() {
      return store.getters["sessions/featured"];
    },
  },
  beforeMount() {
    store.dispatch('attendees/hideSidebar')
  },
  mounted() {
    // console.log('sessions', this.$store.state.sessions);
    if ( !this.$store.state.sessions.sessions || this.$store.state.sessions.sessions.length === 0 ) {
      this.$store.dispatch( "sessions/getSessions", this.$store.state.event.event.slug );
    }
    if ( !this.$store.state.attendees.attendees || this.$store.state.attendees.attendees.length === 0 ) {
      this.$store.dispatch("attendees/getAttendees", this.$store.state.event.event.slug );
    }
  },
  setup() {
    // App Name
    const { appLogoImage } = $themeConfig.app;
    return {
      appLogoImage,
    };
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
</style>