<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left border-primary rounded-lg shadow-lg p-1"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general
        v-if="user"
        :user-data="user"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <!-- <b-tab>

      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password />
    </b-tab> -->
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Profile</span>
      </template>

      <account-setting-information
        v-if="user"
        :user-data="user"
      />
    </b-tab>

    <!-- social links -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Social</span>
      </template>

      <account-setting-social
        v-if="user"
        :user-data="user"
      />
    </b-tab>

    <!-- notification -->
    <!-- <b-tab>

      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Notifications</span>
      </template>

      <account-setting-notification
        v-if="user"
        :notification-data="user"
      />
    </b-tab> -->

    <!-- stage link for webinar mode -->
    <b-tab v-if="showStageLink" @click="goBackToStage()">

      <!-- title -->
      <template #title>
        <feather-icon
          icon="ArrowLeftIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bolder">Back to Stage</span>
      </template>

    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'
import store from '@/store/index'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification,
  },
  computed: {
    showStageLink() {
      return store.state.appConfig.layout.menu.hidden
    },
    user() {
      let userData = store.state.account.user
      if (!userData.avatar) {
        userData.avatar = ''
      }
      if (!userData.profile) {
        userData.profile = ''
      }
      if (!userData.industry) {
        userData.industry = ''
      }
      if (!userData.interests) {
        userData.interests = ''
      }
      if (!userData.social) {
        userData.social = {
          twitter: '',
          facebook: '',
          youtube: '',
          linkedin: '',
          instagram: '',
          website: '',
        }
      }
      return userData
    }
  },
  data() {
    return {
      options1: {
        general: {
          avatar: '',
        },
        info: {},
        social: {
          socialLinks: {
            twitter: 'https://www.twitter.com',
            facebook: '',
            google: '',
            linkedIn: 'https://www.linkedin.com',
            instagram: '',
            quora: ''
          },
          connections: {
            twitter: {
              profileImg: '',
              id: 'johndoe'
            },
            google: {
              profileImg: '',
              id: 'luraweber'
            },
            facebook: {},
            github: {}
          }
        },
      },
    }
  },
  methods: {
    goBackToStage() {
      this.$router.replace('/stage');
    }
  },
  beforeCreate() {
    // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
}
</script>
