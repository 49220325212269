<template>
  <b-card class="border-primary rounded-lg shadow-lg">

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-avatar
          v-if="booking.avatar"
          size="100"
          :text="avatarText(booking.name || booking.firstname + ' ' + (booking.middlename ? booking.middlename + ' ' : '') + booking.lastname )"
          :src="booking.avatar"
          variant="light-primary"
        >
          <feather-icon
            v-if="!booking.name"
            icon="UserIcon"
            size="32"
          />
        </b-avatar>
        <avatar v-if="!booking.avatar" variant="bauhaus" :size="100" :colors="['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']" :name="booking.name || booking.firstname + ' ' + (booking.middlename ? booking.middlename + ' ' : '') + booking.lastname" />
        <!-- <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="booking.avatar"
            height="80"
          />
        </b-link> -->
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="userAvatarUpdated"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text class="small">Allowed JPG, GIF or PNG.</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <validation-observer ref="accountGeneralForm" #default="{ invalid }">
      <b-form class="mt-2" @submit.prevent="updateProfile">
        <b-row>
          <b-col cols="12" lg="3">
            <b-form-group label="Title *" label-for="register-title">
              <validation-provider
                #default="{ errors }"
                name="Title"
                vid="title"
                rules="required"
              >
                <b-form-select
                  id="register-title"
                  v-model="booking.title"
                  label="title"
                  :options="[
                    { value: null, text: '' },
                    { value: 'Mx', text: 'Mx' },
                    { value: 'Ms', text: 'Ms' },
                    { value: 'Mr', text: 'Mr' },
                    { value: 'Dr', text: 'Dr' },
                    { value: 'Prof', text: 'Prof' },
                  ]"
                  name="register-title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="3">
            <b-form-group
              label="First Name *"
              label-for="register-firstname"
            >
              <validation-provider
                #default="{ errors }"
                name="First Name"
                vid="firstname"
                rules="required"
              >
                <b-form-input
                  id="register-firstname"
                  v-model="booking.firstname"
                  :state="errors.length > 0 ? false : null"
                  name="register-firstname"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="3">
            <b-form-group
              label="Middle Name"
              label-for="register-middlename"
            >
              <validation-provider
                #default="{ errors }"
                name="Middle Name"
                vid="middlename"
                rules=""
              >
                <b-form-input
                  id="register-middlename"
                  v-model="booking.middlename"
                  :state="errors.length > 0 ? false : null"
                  name="register-middlename"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="3">
            <b-form-group
              label="Last Name *"
              label-for="register-lastname"
            >
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                vid="lastname"
                rules="required"
              >
                <b-form-input
                  id="register-lastname"
                  v-model="booking.lastname"
                  :state="errors.length > 0 ? false : null"
                  name="register-lastname"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" lg="6">
            <!-- email -->
            <b-form-group label="Email *" label-for="register-email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="register-email"
                  v-model="booking.email"
                  :state="errors.length > 0 ? false : null"
                  name="register-email"
                  placeholder="your.name@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group label="Mobile" label-for="register-mobile">
              <validation-provider
                #default="{ errors }"
                name="Mobile"
                vid="tel"
                rules=""
              >
                <b-form-input
                  id="register-mobile"
                  v-model="booking.mobile"
                  :state="errors.length > 0 ? false : null"
                  name="register-mobile"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group
              label="Organization"
              label-for="register-organization"
            >
              <validation-provider
                #default="{ errors }"
                name="Organization"
                vid="organization"
                rules=""
              >
                <b-form-input
                  id="register-organization"
                  v-model="booking.organization"
                  :state="errors.length > 0 ? false : null"
                  name="register-organization"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group
              label="Job Title"
              label-for="register-designation"
            >
              <validation-provider
                #default="{ errors }"
                name="Job Title"
                vid=""
                rules=""
              >
                <b-form-input
                  id="register-designation"
                  v-model="booking.designation"
                  :state="errors.length > 0 ? false : null"
                  name="register-designation"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          

          <b-col cols="12">
            <b-button
              type="submit"
              :disabled="invalid"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import {
  BAvatar, BFormFile, BButton, BForm, BFormGroup, BFormInput, BFormRow, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import Avatar from 'vue2-boring-avatars'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BAvatar,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormRow,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    ValidationProvider,
    ValidationObserver,
    Avatar,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      booking: this.userData,
      profileFile: null,
      avatarText,

      // validation rules
      required,
      email,
    }
  },
  methods: {
    resetForm() {
      this.booking = this.userData
    },
    updateProfile() {
      // console.log('updatedProfile', this.booking)
      this.booking.name = this.booking.firstname + ' ' + (this.booking.middlename ? this.booking.middlename + ' ': '') + this.booking.lastname
      this.$store.dispatch('account/updateProfile', this.booking)
    },
    userAvatarUpdated(file) {
      // console.log(file)
      this.$store.dispatch('account/uploadProfilePicture', file)
    }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
